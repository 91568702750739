import React from 'react'
import { graphql } from 'gatsby'
import BlogPageLayout from '../components/layouts/blogPageLayout'
import './../components/tags/tags.css'
import PostList from '../components/utils/PostList'
import styled from 'styled-components'

const TagPosts = ({ location, pageContext, data }) => {
  const { tag } = pageContext
  const { nodes, totalCount } = data.allMdx
  const tagHeader = `${totalCount} Post${
    totalCount === 1 ? '' : 's'
  } tagged with ${tag.toUpperCase()}`
  return (
    <BlogPageLayout location={location} pageContext={pageContext} isPost={false}>
      <StyledTitle>{tagHeader}</StyledTitle>      
      <PostList posts={nodes} showTags={false}/>
    </BlogPageLayout>
  )
}

export const StyledTitle = styled.h2`
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: bold;
  margin-top: -14px;
  margin-bottom: 2rem;
  text-align: center;
  color: magenta;
  text-decoration: underline;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji;
`

export default TagPosts

export const pageQuery = graphql`
query tagQuery($tag: String) {
  allMdx(
    limit: 2000
    sort: {fields: [frontmatter___topicId, frontmatter___pageId]}
    filter: {frontmatter: {tags: {in: [$tag]}}}
  ) {
    totalCount
    nodes {
      fields {
        slug
      }
      excerpt
      timeToRead
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        metaTitle
        metaDescription
        tags
        img
      }
    }
  }
}
`